import revive_payload_client_ktJNXckH7R from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.4.1_@types+node@20.11.30_db0@0.2.1_eslint@9.20.0_jiti@2.4_744abd7e34fa5114b075521f4d3cdcf2/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_0o4WXd93si from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.4.1_@types+node@20.11.30_db0@0.2.1_eslint@9.20.0_jiti@2.4_744abd7e34fa5114b075521f4d3cdcf2/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_VGWF5j9EUX from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.4.1_@types+node@20.11.30_db0@0.2.1_eslint@9.20.0_jiti@2.4_744abd7e34fa5114b075521f4d3cdcf2/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_vnmCTxl696 from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.4.1_@types+node@20.11.30_db0@0.2.1_eslint@9.20.0_jiti@2.4_744abd7e34fa5114b075521f4d3cdcf2/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_LHH83L6Hyh from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.4.1_@types+node@20.11.30_db0@0.2.1_eslint@9.20.0_jiti@2.4_744abd7e34fa5114b075521f4d3cdcf2/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_RtH1SuaYM3 from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.4.1_@types+node@20.11.30_db0@0.2.1_eslint@9.20.0_jiti@2.4_744abd7e34fa5114b075521f4d3cdcf2/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/app/apps/onboarding/.nuxt/components.plugin.mjs";
import prefetch_client_Is51BDDL4b from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.4.1_@types+node@20.11.30_db0@0.2.1_eslint@9.20.0_jiti@2.4_744abd7e34fa5114b075521f4d3cdcf2/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_61oDKBidZV from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.29.1_vue@3.5.13_typescript@5.7.3__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_cWsxnPXJ6g from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.29.1_vue@3.5.13_typescript@5.7.3__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_client_WxeWRTgl0D from "/app/node_modules/.pnpm/nuxt-viewport@2.2.0_magicast@0.3.5_rollup@4.29.1_vue@3.5.13_typescript@5.7.3_/node_modules/nuxt-viewport/dist/runtime/plugin.client.js";
import plugin_OHU0vggoM9 from "/app/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_PyU5AjOucg from "/app/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_magicast@0.3.5_nuxt@3.15.4_@parcel+watcher@2.4.1_@type_a78d93b50dbcc26361635e999339fed9/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import _1_gigya_plugin_client_U491P69K7H from "/app/layers/base/plugins/1.gigya-plugin.client.ts";
import _2_user_session_validation_client_BYPNrXf3eW from "/app/layers/base/plugins/2.user-session-validation.client.ts";
import bootstrap_client_n243PjUQtC from "/app/layers/base/plugins/bootstrap.client.ts";
import focus_visible_client_JIkamKZ8y6 from "/app/layers/base/plugins/focus-visible.client.ts";
import http_interceptor_9I5iYs0has from "/app/layers/base/plugins/http-interceptor.ts";
import _0_gigya_plugin_client_VZq2p4KDAf from "/app/apps/onboarding/plugins/0.gigya-plugin.client.ts";
import app_lifecycle_client_TeF8g7qPkg from "/app/apps/onboarding/plugins/app-lifecycle.client.ts";
import image_thumbnail_update_G2rT6oNNpa from "/app/apps/onboarding/plugins/image-thumbnail-update.ts";
import page_view_event_client_sZGbScZEgF from "/app/apps/onboarding/plugins/page-view-event.client.ts";
export default [
  revive_payload_client_ktJNXckH7R,
  unhead_0o4WXd93si,
  router_VGWF5j9EUX,
  payload_client_vnmCTxl696,
  navigation_repaint_client_LHH83L6Hyh,
  chunk_reload_client_RtH1SuaYM3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_Is51BDDL4b,
  switch_locale_path_ssr_61oDKBidZV,
  i18n_cWsxnPXJ6g,
  plugin_client_WxeWRTgl0D,
  plugin_OHU0vggoM9,
  plugin_PyU5AjOucg,
  _1_gigya_plugin_client_U491P69K7H,
  _2_user_session_validation_client_BYPNrXf3eW,
  bootstrap_client_n243PjUQtC,
  focus_visible_client_JIkamKZ8y6,
  http_interceptor_9I5iYs0has,
  _0_gigya_plugin_client_VZq2p4KDAf,
  app_lifecycle_client_TeF8g7qPkg,
  image_thumbnail_update_G2rT6oNNpa,
  page_view_event_client_sZGbScZEgF
]